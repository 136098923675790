import { useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import useOnClickOutside from '~/hooks/useOnClickOutside'
import useClientMediaQuery from '~/hooks/useMediaQuery'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function ImagePopup({ fields }: { fields: any }) {
  const [isOpen, setIsOpen] = useState(false)
  const isDesktop = useClientMediaQuery('(min-width: 992px)')
  const ref = useRef(null)
  useOnClickOutside(ref, handleClickOutside)

  function handleClickOutside() {
    setIsOpen(false)
  }

  function closeModal() {
    sessionStorage.setItem('hide', 'true')
    setIsOpen(false)
  }

  useEffect(() => {
    const { documentElement: html } = document

    setTimeout(() => {
      {
        !sessionStorage.getItem('hide') && setIsOpen(true)
      }
    }, 2000)

    if (isOpen) {
      html.classList.add('overflow-hidden')
    } else {
      html.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: isDesktop ? 'auto' : '0%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'transparent',
      border: 0,
      width: isDesktop ? '600px' : '100%',
      height: isDesktop ? 'auto' : '300px',
      backgroundColor: 'transparent',
      borderRadius: '1.5rem',
      zIndex: '9999'
    }
  }

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <div ref={ref} className="overflow-y-scroll relative">
        <div className="absolute left-auto right-2 top-2 z-10 cursor-pointer" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            className="w-[24px] h-[24px] lg:w-[32px] lg:h-[32px]"
          >
            <path fill="#fff" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6L6.4 19Z" />
          </svg>
        </div>
        <a href="/over-ons/" rel="noreferrer">
          <LossePlaatjie src={fields?.image} />
          {fields?.link && (
            <p className="bg-black -mt-[1px] text-center pb-4">
              <a
                href={isDesktop ? fields?.link?.mediaItemUrl : 'https://player.vimeo.com/video/826804618?h=8576c374cf'}
                target="_blank"
                className="button"
                rel="noopener noreferrer"
              >
                Meer info
              </a>
            </p>
          )}
        </a>
      </div>
    </ReactModal>
  )
}
